import QueryString from 'qs'
import { propEq } from 'ramda'
import { fromEvent, merge } from 'rxjs'
import { filter } from 'rxjs/operators'

import { ScrollBlockers, scrollToId, visuallyHide, visuallyShow } from '@app-helpers'

export function videoPlayerOverLayService(
    cityLoopVideo: HTMLVideoElement,
    sectionTitle: HTMLElement,
    videoSection: HTMLElement,
    scrollToIdString: string,
    TOUR_SCROLL_BLOCKER: string,
    TOUR_VIDEO_END_CUT: number,
    locationHref: string,
    homepagePositionFix: boolean = false,
) {
    merge(
        fromEvent(window, 'load').pipe(filter((): boolean => {
            const query = QueryString.parse(window.location.search, { ignoreQueryPrefix: true }) as any
            return propEq('tour', '1', query)
        })),
    )
        .subscribe(async () => {

            await scrollToId(scrollToIdString)

            ScrollBlockers.add(TOUR_SCROLL_BLOCKER)

            visuallyHide(sectionTitle)
            //hide(cityLoopVideo);

            if (homepagePositionFix) {
                videoSection.classList.add('video-is-playing')
            }

            ScrollBlockers.remove(TOUR_SCROLL_BLOCKER)

            if (homepagePositionFix) {
                videoSection.classList.remove('video-is-playing')
            }

            visuallyShow(sectionTitle)

            window.location.href = locationHref
        })
}
